<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="6"
      >

        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title class="white--text">{{$t('TEyIL1sPGyURCWPdjhYC0')}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
              validation
              class="mb-5"
            >
              <v-text-field
                v-model.trim="email"
                :label="$t('gwhfwYPbyuea24RViKirw')"
                name="email"
                prepend-icon="mdi-email-check-outline"
                type="email"
                required
                :rules="emailRules"
              />
              <v-text-field
                v-model.trim="password"
                id="password"
                :label="$t('3m2HuiArv0CmDnbEqCY0y')"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                autocomplete="new-password"
                :counter="6"
                required
                :rules="passwordRules"
              />
            </v-form>

            <VueRecaptcha
              :loadRecaptchaScript="true"
              :sitekey="$root.recaptchaSitekey"
              ref="recaptcha"
              @verify="regUser"
              @expired="onCaptchaExpired"
              size="invisible"
            >
            </VueRecaptcha>

            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
              :disabled="!valid || loading"
            >{{$t('roG8Qb393tbpdHU8JLlrF')}}</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <p>{{$t('f7ybpJaDTKdv0xkwJQZHa')}} <router-link to="/login">{{$t('4RT_LqVSYDsKEbBrmK_XK')}}</router-link>
            </p>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { VueRecaptcha },
  data () {
    return {
      valid: false,
      email: '',
      password: '',
      emailRules: [
        v => !!v || this.$t('_YtBk2TxZ-e_28JJfhLD4'),
        v => /.+@.+\..+/.test(v) || this.$t('ghAVjRmoFwk6v_bH4Zxfq')
      ],
      passwordRules: [
        v => !!v || this.$t('2sul8fMJO4WqqqMJO0W54'),
        v => (v && v.length >= 6) || this.$t('jvbuvlY2zzC2NKWUD3nAN')
      ]
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  methods: {
    ...mapActions(['setLoading']),

    async regUser (recaptchaToken) {
      const user = {
        email: this.email,
        password: this.password,
        lang: navigator.language.slice(0, 2) ||
          navigator.systemLanguage.slice(0, 2) ||
          navigator.userLanguage.slice(0, 2) ||
          'en',
        recaptchaToken
      }
      const reg = await this.$store.dispatch('regUser', user)
      if (reg) {
        this.$router.push({ name: 'home' })
      }
      this.email = ''
      this.password = ''
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.setLoading(true)
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    }

    // async onSubmit () {
    //   if (this.$refs.form.validate()) {
    //     const user = {
    //       email: this.email,
    //       password: this.password
    //     }
    //     const reg = await this.$store.dispatch('regUser', user)
    //     if (reg) {
    //       this.$router.push({ name: 'login' })
    //     }
    //   }
    // }
  }
}
</script>

